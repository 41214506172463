<template>
  <!-- 页面name：运营任务 -->
  <div class="dict">
        <!--  v-if="isAdd" -->
    <!-- 表单 -->
    <div class="form-header-btn">
      <div class="title">运营项目</div>
      <div>
        <el-button type="primary" v-if="isAdd" @click="handleAdd(3)" 
          >新增</el-button
        >
      </div>
    </div>
    <div class="form-header-btn">
      <div>
        <el-form :model="form" onsubmit="return false" :inline="true" class="search-form">
          <el-form-item>
            <el-input v-model="form.keyword" placeholder="运营项目查询" />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="searchFun"
              >查询</el-button
            >
          </el-form-item>
          <el-form-item>
            <el-button @click="resetFun" icon="el-icon-refresh-right" class="reset">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div>
        <el-radio-group v-model="openLevel" @change="changeOpenLevel">
          <el-radio :label="0">展开一级</el-radio>
          <el-radio :label="2">展开二级</el-radio>
          <el-radio :label="3">展开全部</el-radio>
        </el-radio-group>
      </div>
    </div>
    <el-table
      :data="tableData"
      :header-cell-style="{
        background: '#F1F4F9',
        color: '#5E6D9B',
        fontWeight: '400',
        'text-align':'center'
      }"
      :row-class-name="tableRowClassName"
      v-loading="tableLoading"
      style="width: 100%; margin-bottom: 1.04167vw;min-height:0;overflow-y: scroll;overflow:visible;"
      ref="tableDatas" 
      :tree-props="{ children: 'itemList', hasChildren: 'hasChildren' }"
      :row-key="row => { return row.id }"
      :expand-row-keys="expandRow"
    >
      <el-table-column  prop="itemName" width="200" label="运营项目" align="left" >
        <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" :content="scope.row.itemName" placement="top">
              <span class="itemName">{{scope.row.itemName}}</span>
            </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column  prop="predictDay" width="190" label="预计办理时长（天）" align="center" >
      </el-table-column>
      <el-table-column  prop="jobId" label="岗位" align="center" >
        <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" :content="scope.row.jobId" placement="top">
              <span class="jobId">{{scope.row.jobId}}</span>
            </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column  prop="timeoutUserType" width="160" label="逾期反馈人" align="center" >
        <template slot-scope="scope">
          <span v-if="scope.row.timeoutUserType">{{scope.row.timeoutUserType.label}}</span>
        </template>
      </el-table-column>
      <el-table-column  prop="isMust" label="完成情况" align="center" >
        <template slot-scope="scope">
          <span v-if="scope.row.isMust">{{scope.row.isMust.label}}</span>
        </template>
      </el-table-column>
      <el-table-column  prop="updateTime" width="150" label="更新时间" align="center" >
      </el-table-column>
      <el-table-column  prop="sort" label="排序" width="180" align="center" >
        <template slot-scope="scope">
          <div v-if="scope.row.level != 3">
            <!-- {{scope.$index}} -->
            <!-- {{scope.row.itemList.length}} -->
            <el-button
              type="text"
              title="上移"
              icon="el-icon-top"
              size="mini"
              v-if="scope.row.index!=0"
              circle
              @click="changeSort('1', scope.row)"
            ></el-button>
            <el-button
              type="text"
              title="移到顶部"
              v-if="scope.row.index!=0"
              icon="el-icon-upload2"
              size="mini"
              circle
              @click="changeSort('2', scope.row)"
            ></el-button>
            <el-button
              type="text"
              title="下移"
              icon="el-icon-bottom"
              size="mini"
              v-if="scope.row.isLast"
              circle
              @click="changeSort('3', scope.row)"
            ></el-button>
            <el-button
              type="text"
              title="移到底部"
              icon="el-icon-download"
              v-if="scope.row.isLast"
              size="mini"
              circle
              @click="changeSort('4', scope.row)"
            ></el-button>
          </div>
          <div v-else>{{scope.row.sort}}</div>
        </template>
      </el-table-column>
      <el-table-column  prop="isUse" label="状态" width="120" align="center" >
        <template slot-scope="scope">
          <span v-if="scope.row.isUse">
            <el-switch :width="30"   @change="val=>changeIsUse(val,scope.row)" v-model="scope.row.isUse.value" active-value="1"  inactive-value="0">
          </el-switch>
          {{scope.row.isUse.label}}
        </span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" fixed="right" width="250">
        <template slot-scope="scope">
          <el-button class="textBtn" type="text" v-if="scope.row.level == 3 && isView" @click="handleEdtail(scope.row)">查看</el-button>
          <!-- v-if="isUpdate" -->
          <!-- v-if="isDelete"  -->
          <el-button class="textBtn" v-if="isUpdate" type="text" @click="handleEdit(scope.row)">编辑</el-button>
          <el-popconfirm
            :icon="'el-icon-error'"
            :icon-color="'#C6000B'"
            title="你确定要删除此项目吗？" @confirm="handleDel(scope.row)"
          >
            <el-button  v-if="isDelete" class="textBtn" slot="reference"  type="text">删除</el-button>
          </el-popconfirm>
          <el-button  class="textBtn" type="text" @click="handleAddNext(scope.row)" v-if="scope.row.level != 3 && isAddxj">添加下级</el-button>
          <el-button  class="textBtn" type="text" v-if="isAddtj" @click="handleAdd(scope.row.level,scope.row)">新增同级</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="quick-add-project" v-if="!tableLoading && tableData.length == 0 && isAdd" @click="handleAdd(1)">
      <img :src="iconAdd" alt="">
      <span>快速创建</span>
    </div>
    
    <!-- <div class="createBtn" v-if="tableData.length == 0">
      <el-button type="text" @click="handleAdd(1)" icon="el-icon-plus">快速创建</el-button>
    </div> -->
    <!-- 对话框 -->
    <el-dialog
      :title="title == '0' ? '新增' : '编辑'"
      :visible.sync="dialogVisible"
      width="38%"
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <el-form
        :model="dialogForm"
        :rules="rules"
        ref="dialogForm"
      >
        <el-form-item class="form-choose-item" prop="itemName" >
          <el-input
            class="form-input"
            v-model.trim="dialogForm.itemName"
            clearable
            :maxlength="10"
            placeholder="请输入名称"
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleClose"  class="reset">取消</el-button>
        <el-button type="primary" v-if="title == '0'" @click="dialogAdd('dialogForm')">新增</el-button>
        <el-button type="primary" v-else @click="handleEditBtn('dialogForm')">修改</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import LTable from "@/components/tableComponent/index.vue";
import iconAdd from "@/assets/img/icon_add.png"; //添加icon
import {
  getPermissionButton,
  isHasPermission,
} from "@/utils/getUrlPermissionButtons";
import { mapGetters, mapState } from "vuex";
export default {
  components: {
    LTable,
  },
  data() {
    var checkNum = (rule, value, callback) => {
      if (!value) {
        return callback();
      }
      let valueString = value + "";
      let numberAuth = /[^\d]/g;
      if (numberAuth.test(valueString)) {
        callback(new Error("只支持数字"));
      } else if (valueString.length > 1 && valueString.substr(0, 1) === "0") {
        callback(new Error("只支持数字，首位不能是0"));
      } else {
        callback();
      }
    };
    return {
      keyword:'',
      iconAdd,
      dialogVisible: false,
      tableLoading: false, // tableloading
      page: {
        num: 1,
        size: 10,
        total: 0,
        pageSizes: [1, 20, 50, 100, 500],
      }, // 分页信息
      pageFlag: false,
      columns: [
        {
          label: "运营项目",
          slotName: "itemName",
        },
        {
          label: "预计办理时长（天）",
          slotName: "predictDay",
        },
        {
          label: "岗位",
          slotName: "jobId",
        },
        {
          label: "逾期反馈人",
          slotName: "timeoutUserType",
        },
        {
          label: "完成情况",
          slotName: "userName",
        },
        {
          label: "更新时间",
          slotName: "updateTime",
        },
        {
          label: "排序",
          slotName: "sort",
        },
        {
          label: "状态",
          slotName: "isUse",
        },
        {
          label: "操作",
          slotName: "handle",
        },
      ],
      title: "",
      form: {
        keyword: "",
        options: [
          {
            label: "异常",
            value: "0",
          },
          {
            label: "正常",
            value: "1",
          },
        ],
      },
      current: 1,
      selectArrs: [],
      currentIndex: 1,
      pageSize: 10,
      dialogForm: {
        itemName: "",
        parentId:'',
        id:'',
      },
      tableData: [],
      // 对话框表单校验
      rules: {
        itemName: [
          {
            required: true,
            message: "请输入项目名称",
            trigger: "blur",
          },
        ],
      },
      permissionButtonList: [], // 权限按钮list
      isUpdate: false,
      isAdd: false,
      isDelete: false,
      isAddtj:false,
      isAddxj:false,
      isView:false,
      openLevel:3,
      expandRow:[],
      dictList:{
        operate_timeout_user_type:[],
        operate_is_use:[],
        operate_is_must:[],
      }
    };
  },
  computed: {
    ...mapGetters(["permissions"]),
    ...mapState({
      menuList: (state) => state.common.menuList,
    }),
    showNum() {
      let start = (this.current - 1) * this.ipagination.size + 1;
      let end =
        this.current * this.ipagination.size > this.ipagination.total
          ? this.ipagination.total
          : this.current * this.ipagination.size;
      return this.ipagination.total === 0
        ? this.ipagination.total
        : `${ start }-${ end }`;
    },
  },
  watch: {
    permissionButtonList: {
      handler(val) {
        if (val) {
          this.isUpdate = val.indexOf("operation:edit") != -1; 
          this.isAdd = val.indexOf("operation:addProject") != -1; //添加
          this.isAddtj = val.indexOf("operation:addtj") != -1;  //添加同级
          this.isDelete = val.indexOf("operation:del") != -1; //删除
          this.isAddxj= val.indexOf("operation:addxj") != -1; //添加下级
          this.isView= val.indexOf("operation:view") != -1; //查看
        }
      },
      deep: true,
    },
    menuList: {
      handler(val) {
        if (val) {
          this.getPermissionButton(this.$route.path, (data) => {
            console.log(data, "右侧权限按钮按钮按钮按钮啊");
            this.permissionButtonList = data;
          });
        }
      },
      deep: true,
    },
  },
  created() {
    // this.init();
    // menulist一直在时这个方法不可缺少
    this.queryDict()
    this.getPermissionButton(this.$route.path, (data) => {
      console.log(data, "右侧权限按钮按钮按钮按钮啊created");
      this.permissionButtonList = data;
    });
  },
  activated(){
    this.init();
  },
  methods: {
    queryDict() {
      var dict = Object.keys(this.dictList);
      let requests = dict.map(
        (item) =>
          new Promise((resolve, reject) => {
            this.$api
              .getDictionary({ type: item })
              .then((res) => {
                res.data.fieldName = item;
                resolve(res.data);
              })
              .catch(() => {
                resolve([]);
              });
          })
      );
      Promise.all(requests).then((res) => {
        res.forEach((item) => {
          this.dictList[item.fieldName] = item.data;
        });
      });
    },
    getPermissionButton,
    isHasPermission,
    /** 初始化 */
    init() {
      if (this.form.keyword == "") {
        this.form.keyword = null;
      }
      // this.page.num = 1;
      this.tableLoading = true;
      this.$api
        .getOperateList(
          Object.assign({
            itemName: this.form.keyword,
          })
        )
        .then((res) => {
          this.tableLoading = false;
          if (res.data.code == "0") {
            this.initTableData(res.data.data)
            this.tableData = res.data.data;
            setTimeout(()=>{
              this.changeOpenLevel(this.openLevel)
            },200)
          } else {
            this.tableData = [];
            this.page.total = 0;
            this.pageFlag = false;
            this.$message.error(res.data.msg);
          }
        });
    },
    initTableData(list){
      const Cdata = list.map((item,index)=>{
        const initIndex = data=>{
          item.index = index
          if(index == list.length-1){
            item.isLast = 0
          }else{
            item.isLast = 1
          }
          if(data.itemList){
            this.initTableData(data.itemList)
          }
        }
        initIndex(item)
      })
    },
    // 展开层级
    changeOpenLevel(val){
      this.resetTabDate();
      console.log(val)
      if(val == 4){
        this.expandRow = []
        if(this.tableData){
          this.tableData.forEach(e=>{
            this.expandRow.push(e.id)
            this.$refs.tableDatas.toggleRowExpansion(e, true);
            if(e.itemList){
              e.itemList.forEach(el=>{
                this.expandRow.push(el.id)
                this.$refs.tableDatas.toggleRowExpansion(el, true);
                if(el.itemList){
                  el.itemList.forEach(ele=>{
                    this.$refs.tableDatas.toggleRowExpansion(ele, true);
                    this.expandRow.push(ele.id)
                  })
                }
              })
            }
          })
        }
      }else  if(val == 3){
        this.expandRow = []
        this.tableData.map((item) => {
          this.expandRow.push(item.id);
          this.$refs.tableDatas.toggleRowExpansion(item, true);
          if (item.itemList && item.itemList.length > 0) {
            item.itemList.map((itemChild) => {
              this.expandRow.push(itemChild.id);
              this.$refs.tableDatas.toggleRowExpansion(itemChild, true);
            });
          }
        });
      }else if(val == 2){
        this.expandRow = []
        this.tableData.map((item) => {
          this.expandRow.push(item.id);
          this.$refs.tableDatas.toggleRowExpansion(item, true)
        });
      }
    },
    //收起展开菜单
    resetTabDate(arr) {
      this.expandRow = [];
      let data = arr || this.tableData;
      data.forEach((item) => {
        this.$refs.tableDatas.toggleRowExpansion(item, false);
        if (
          Object.prototype.toString.call(item.itemList) ==
          "[object Array]"
        ) {
          this.resetTabDate(item.itemList);
        }
      });
    },
    // 添加一级二级和三级
    handleAdd(level,row){
      if(level>2){
        let parentId = ''
        if(row){
          parentId = row.treePath
        }
        console.log(row)
        this.$router.push({
          path:'/editOperationalItems',
          query:{
            isEdit:0,
            parentId:parentId
          }
        })
      }else{
        this.dialogVisible = true;
        this.title = "0";
        this.dialogForm.parentId = row.parentId
      }
    },
    // 新增下级
    handleAddNext(row){
        console.log(row)
      if(row.level == 2){
        this.$router.push({
          path:'/editOperationalItems',
          query:{
            isEdit:0,
            parentId:row.treePath+','+row.id
          }
        })
      }else if(row.level == 1){
        this.dialogVisible = true;
        this.title = "0";
        this.dialogForm.parentId = row.id
      }
    },
    handleEdtail(row){
      this.$router.push({
        path:'/editOperationalItems',
        query:{
          isEdit:2,
          id:row.id
        }
      })
    },
    // 查询
    searchFun() {
      this.keyword = this.form.keyword
      this.page.num = 1;
      // this.init();
    },
    // 重置按钮
    resetFun() {
      this.form.keyword = "";
      this.keyword = "";
      this.page.num = 1;
      this.init();
    },
    /** 对话框-新增 */
    dialogAdd(dialogForm) {
      this.$refs[dialogForm].validate((valid) => {
        if (valid) {
          this.$api.operateSave(this.dialogForm).then((res) => {
            if (res.data.code == "0") {
              this.$message.success("添加成功");
              this.init();
              this.handleClose();
            } else {
              this.$message.error(res.data.msg);
            }
          });
        }
      });
    },
    swapArray(arr, index1, index2) {
      let teatArr = JSON.parse(JSON.stringify(arr))
      teatArr[index1] = teatArr.splice(index2, 1, teatArr[index1])[0];
        return teatArr;
    },
    // 修改排序
    changeSort(direction,item){
      let arr = JSON.parse(JSON.stringify(this.tableData))
      let childArr = []
      let sortList = []
      if(direction == 1){
        // 向上移一个
        if(item.level == 1){
          for (let index = 0; index < arr.length; index++) {
            const element = arr[index];
            if(element.id == item.id){
              if(index == 0){
                return
              }
              [arr[index],arr[index-1]] = [arr[index-1],arr[index]];
            }
          }
          arr.forEach((e,index)=>{
              sortList.push({
                sort:index,
                id:e.id
              })
          })
        }else if(item.level == 2){
          for (var index = 0; index < arr.length; index++) {
            let element = arr[index];
            if(element.id == item.parentId){
              var list = element.itemList
              for (var index2 = 0; index2 < list.length; index2++) {
                let ele = list[index2];
                if(ele.id == item.id){
                  if(index2 == 0){
                    return
                  }
                  [list[index2],list[index2-1]] = [list[index2-1],list[index2]];
                }
              }
              list.forEach((e,index)=>{
                sortList.push({
                  sort:index,
                  id:e.id
                })
              })
            }
          }
        }
      }else if(direction == 2){
        // 移到顶部
        if(item.level == 1){
          for (let index = 0; index < arr.length; index++) {
            const element = arr[index];
            if(element.id == item.id){
              if(index == 0){
                return
              }
              [arr[index],arr[0]] = [arr[0],arr[index]];
            }
          }
          arr.forEach((e,index)=>{
              sortList.push({
                sort:index,
                id:e.id
              })
          })
        }else if(item.level == 2){
          for (var index = 0; index < arr.length; index++) {
            let element = arr[index];
            if(element.id == item.parentId){
              var list = element.itemList
              for (var index2 = 0; index2 < list.length; index2++) {
                let ele = list[index2];
                if(ele.id == item.id){
                  if(index2 == 0){
                    return
                  }
                  [list[index2],list[0]] = [list[0],list[index2]];
                }
              }
              list.forEach((e,index)=>{
                sortList.push({
                  sort:index,
                  id:e.id
                })
              })
            }
          }
        }
      }else if(direction == 3){
        // 向下移一个
        if(item.level == 1){
          for (let index = 0; index < arr.length; index++) {
            const element = arr[index];
            if(element.id === item.id){
              console.log('op',element.id , item.id,index, (arr.length-1))
              // console.log(element)
              if(index == (arr.length-1)){
                return
              }
              let wapArr = this.swapArray(arr,index,index+1)
              wapArr.forEach((e,index)=>{
                  sortList.push({
                    sort:index,
                    id:e.id
                  })
              })
            }
          }
        }else if(item.level == 2){
          for (var index = 0; index < arr.length; index++) {
            let element = arr[index];
            if(element.id == item.parentId){
              var list = element.itemList
              for (var index2 = 0; index2 < list.length; index2++) {
                let ele = list[index2];
                if(ele.id === item.id){
                  if(index2 == list.length-1){
                    return
                  }
                  let wapArr = this.swapArray(list,index2,index2+1)
                  wapArr.forEach((e,index)=>{
                      sortList.push({
                        sort:index,
                        id:e.id
                      })
                  })
                }
              }
            }
          }
        }
      }else if(direction == 4){
        // 移到底部
        if(item.level == 1){
          for (let index = 0; index < arr.length; index++) {
            const element = arr[index];
            if(element.id === item.id){
              let wapArr = this.swapArray(arr,index,arr.length-1)
              wapArr.forEach((e,index)=>{
                  sortList.push({
                    sort:index,
                    id:e.id
                  })
              })
            }
          }
        }else if(item.level == 2){
          for (var index = 0; index < arr.length; index++) {
            let element = arr[index];
            if(element.id == item.parentId){
              var list = element.itemList
              for (var index2 = 0; index2 < list.length; index2++) {
                let ele = list[index2];
                if(ele.id == item.id){
                  if(index2 == list.length-1){
                    return
                  }
                  let wapArr = this.swapArray(list,index2,list.length-1)
                  wapArr.forEach((e,index)=>{
                      sortList.push({
                        sort:index,
                        id:e.id
                      })
                  })
                }
              }
            }
          }
        }
      }
      this.$api.updateSort({
        sortList:sortList
      }).then(res=>{
        if(res.data.code == '0'){
          this.$message.success('修改成功')
          this.init()
        }else{
          this.$message.error(res.data.msg)
        }
      })
    },
    /** 对话框-修改提交 */
    handleEditBtn(dialogForm) {
      this.$refs[dialogForm].validate((valid) => {
        if (valid) {
          this.$api.operateEdit(this.dialogForm).then((res) => {
            if (res.data.code == "0") {
              this.$message.success("修改成功");
              this.dialogVisible = false;
              this.init();
              this.handleClose();
            } else {
              this.$message.error(res.data.msg);
            }
          });
        }
      });
    },
    isUser(val){
      if(val == 1){
        return true
      }else{
        return false
      }
    },
    /** 对话框-关闭事件 */
    handleClose() {
      this.dialogVisible = false;
      this.$nextTick(() => {
        this.dialogForm = {
          itemName: "",
          parentId: "",
          id:'',
        };
        this.$refs["dialogForm"].clearValidate();
      });
    },
    /** 行内-编辑 */
    handleEdit(row) {
      if(row.level>2){
        this.$router.push({
          path:'/editOperationalItems',
          query:{
            isEdit:1,
            // parentId:row.id,
            id:row.id
          }
        })
      }else{
        this.dialogForm = {
          itemName: row.itemName,
          id:row.id,
          parentId:row.parentId
        };
        this.title = "1";
        this.dialogVisible = true;
      }
    },
    /** 行内-删除 */
    handleDel(row) {
      if(row.itemList){
        this.$message.error('删除失败！有下级项目时不可删除~');
        return
      }
        this.$api.operateDel(row.id).then((res) => {
          if (res.data.code == "0") {
            this.$message.success("删除成功");
            // this.page.num = 1;
            this.init();
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    /** 分页 */
    handleCurrentChange(val) {
      console.log(val);
      this.page.num = val;
      this.init();
    },
    handleSizeChange(val) {
      console.log(val);
      this.page.size = val;
      this.page.num = 1;
      this.init();
    },
    changeIsUse(val,row){
      if(row.isUse.value == 1){
        row.isUse.label = '启用'
      }else{
        row.isUse.label = '禁用'
      }
      this.$api.operateEdit({isUse:row.isUse.value ,id:row.id}).then(res=>{
        if(res.data.code == 0){
          this.init();
        }else{
          setTimeout(()=>{
            if(val == 1){
              row.isUse = {
                value:'0',
                label:'禁用'
              }
            }else{
              row.isUse = {
                value:'1',
                label:'启用'
              }
            }
            this.$forceUpdate()
          },100)
          this.$message.error('shibai')
        }
      }).catch(error=>{
        setTimeout(()=>{
          if(val == 1){
            row.isUse = {
              value:'0',
              label:'禁用'
            }
          }else{
            row.isUse = {
              value:'1',
              label:'启用'
            }
          }
          this.$forceUpdate()
        },100)
        this.$message.error(error)
      })
    },
    //搜索获取高亮行
    tableRowClassName({row, rowIndex}) {
      let returnFlag = false
      if(this.keyword){
        let list = this.keyword
        let nameList = row.itemName
        if(nameList.indexOf(list) != -1){
          returnFlag = true
        }
        if (returnFlag) {
           return 'search-row'; //light-row
        }
      }
      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/select_list.scss";
.dict {
  background-color: #ffffff;
  height: calc(100% - 56px);
  padding: 26px 30px 30px 30px;
  .tableList {
    height: calc(100% - 106px);
    position: relative;
  }
}
.createBtn{
  position: absolute;
  top: 50%;
  z-index: 50;
  // left: 20%;
  width: 90%;
  text-align: center;
  background-color: #fff;
  height: 80px;
}
.form-header-btn {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  align-items: center;
  .sheet-btn {
    background: #2a00b2;
    box-shadow: 0px 3px 10px rgba(52, 85, 236, 0.4);
    opacity: 1;
    border-radius: 4px;
    font-size: 14px;
    color: #ffffff;
  }

  /deep/.el-button + .el-button {
    margin-left: 20px;
  }
}
.title{
  font-weight: bold;
  font-size: 22px;
}
.l-pages {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
  .total-num {
    font-family: SourceHanSansCN-Regular;
    font-size: 14px;
    color: #7c7c7c;
  }
  .el-pagination {
    padding: 0;
    .el-pagination__sizes {
      margin: 0 0 0 8px;
    }
  }
  .el-pagination .el-select .el-input {
    .el-input__icon {
      right: 20px;
    }
    input {
      width: 100px;
      height: 32px;
      border: 1px solid #e8ecef;
      opacity: 1;
      border-radius: 4px;
      padding: 3px 10px !important;
    }
  }
  .btn-prev,
  .btn-next,
  .el-pager li {
    width: 32px;
    height: 32px;
    border-radius: 6px;
    border: 1px solid #e8ecef !important;
  }
  .btn-next {
    margin-left: 8px;
  }
  .el-pager li {
    line-height: 30px;
    border-radius: 6px;
    font-size: 14px;
    color: #6a6d77;
    margin-left: 8px;
    &:hover {
      background-color: #004ea2 !important;
      border: 1px solid #004ea2 !important;
      color: #ffffff;
    }
  }
  .el-pager li.active {
    background-color: #004ea2 !important;
    border: 1px solid #004ea2 !important;
    color: #ffffff;
  }
}
.quick-add-project{
  width: 100%;
  height: 63px;
  border: 1px dashed #B5BDCF;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #003685;
  font-weight: 400;
  margin-top: 5px;
  cursor: pointer;
  img{
    width: 16px;
    height: 16px;
    margin-right: 6px;
  }
}
.textBtn{
  margin-left: 10px;
}
.itemName{
  // width: 30px;
  width: 9em;
  display: inline-block;
  vertical-align: bottom;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  // line-height: 15px;
}
.jobId{
  width: 100%;
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.item{
  display: inline-block;
}
</style>
